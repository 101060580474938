import React, { useEffect, useRef, useState } from "react";
import BannerSvg from "../../../../assets/images/theme-banner.svg";
import { getAllThemes, onInstallTheme } from "../../../../actions/themeActions";
import NoImageFoundSvg from "../../../../assets/images/theme-placeholder.svg";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/Loading";
import PopupConfirm from "../../common/PopupConfirm";
import { useNav } from "../../../../helpers/customHook";
import Card from "../Card";

const Discover = (props: any) => {
    const [isLoading, setLoading] = useState(false);
    const [themes, setThemes] = useState([]) as any[];
    const navigateTo = useNav();
    const { selectedStore } = useSelector((state: any) => state.ui);
    const [search, setSearch] = useState('');
    const dispatch: any = useDispatch();
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [isFixed, setIsFixed] = useState(false);
    const headerRef = useRef(null); // Reference to the header element
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        loadThemes();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, []);
    const loadThemes = async () => {
        const response = await dispatch(getAllThemes());
        if (response && response.themes) {
            setThemes(response.themes);
        }
    }
    const handleScroll = () => {
        console.log({ headerRef })
        const headerOffsetTop = headerRef.current.offsetTop;
        if (window.scrollY > headerOffsetTop) {
            setIsFixed(true);
        } else {
            setIsFixed(false);
        }
    };
    console.log({ isFixed })
    const installTheme = async (theme: any) => {
        const title = `Theme Installation`;
        setPopupConfirm({
            title,
            renderContent: () => {
                return <div style={{ display: "flex", gap: 10, alignItems: 'center', flexDirection: 'column' }}>
                    <div>
                        <img style={{ height: 360 }} src={theme.presets && theme.presets.length > 0 && theme.presets[0]?.images.length > 0 ? `${theme.baseUrl}${theme?.presets[0]?.images[0]}` : NoImageFoundSvg} alt="preview" />
                    </div>
                    <div>
                        <div style={{ fontWeight: "bold", fontSize: "20px" }}>{theme.title}</div>
                        {theme.description && <div style={{ fontSize: "13px", color: "#808080" }}>{theme.description}</div>}
                        {theme.auth && <div style={{ fontSize: "13px", color: "#606060" }}>Published by{theme.auth}</div>}
                    </div>
                </div >
            },
            yes: {
                label: "Confirm",
                callback: async () => {
                    setLoading(true);
                    setPopupConfirm(null);
                    const resp = await dispatch(onInstallTheme(theme.id));
                    if (resp?.status) {
                        loadThemes();
                    }
                    setLoading(false);
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    const openConfig = () => {
        navigateTo(`/${selectedStore.name}/themes/installed`)
        // navigateTo(`/${theme.name.toLowerCase()}/themeconfigurator/${theme.name}`, { state: { themeId: theme.id } })
    }
    return <div className="discover">
        <div className="header">
            <div className="desc">
                <div className="title">
                    <div>
                        Shopletzy <span style={{ fontWeight: 500 }}>Themes</span>,
                    </div>
                    <div>
                        <span style={{ fontWeight: 500 }}>Crafted for</span> Commerce Excellence
                    </div>
                </div>
                <div className="subtitle">Choose from a wide range of themes to make your store look stunning</div>
            </div>
            <div className="banner">
                <img src={BannerSvg} alt="banner" />
            </div>
        </div>
        <div className="theme-search" ref={headerRef}>
            <div className="title">Themes</div>
            <input type="text" placeholder="Search" className="search" onChange={(e) => setSearch(e.target.value.trim())} />
        </div>
        <div className="list">
            {themes.map((theme: any, index: number) => {
                return (search.length === 0 || theme.title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) && <Card theme={theme} openConfig={openConfig} installTheme={installTheme} type="discover" />
            })}
        </div>
        {isLoading && <Loading background="#00000070"></Loading>}
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </div>
}
export default Discover;