import React, { useEffect, useState } from "react";
import "../../../../assets/sass/dsk/btheme.sass";
import { useDispatch } from "react-redux";
import SearchSvg from "../../../../assets/images/search.svg";
import { Switch } from "antd";
import ColorPalette from "../../common/ColorPalette";
import Loading from "../../common/Loading";
import { useLocation } from "react-router-dom";
import { getThemeConfig, onUpdateThemeConfig, onUpdateThemePreset } from "../../../../actions/themeActions";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNav } from "../../../../helpers/customHook";
import { MediaAssets } from "../../common/mediaAssets";
import { onUploadMedia } from "../../../../actions/commonAction";
import { motion } from "framer-motion";
import Card from "./Card";
import PopupConfirm from "../../common/PopupConfirm";

const Configurator = (props: any) => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigateTo = useNav();
    const [config, setConfig]: any = useState({});
    const [selectedSection, setSelectedSection] = useState(null);
    const [updatedConfig, setUpdatedConfig] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [search, setSearch] = useState("");
    useEffect(() => {
        if (state?.themeId) {
            loadConfig(state?.themeId);
        }
        //eslint-disable-next-line
    }, [state]);

    const loadConfig = async (themeId) => {
        setIsLoading(true);
        const resp = await getThemeConfig(themeId)(dispatch);
        if (resp) {
            setConfig(resp);
            if (selectedSection === null) {
                if (resp.configTemplate.defaultTemplates) {
                    setSelectedSection("defaultTemplates")
                } else if (resp?.configTemplate?.sections) {
                    if (Object.keys(resp.configTemplate.sections)[0]) {
                        setSelectedSection(Object.keys(resp.configTemplate.sections)[0])
                    }
                }
            }
        }
        if (resp?.configuration) {
            setUpdatedConfig(resp.configuration);
        }
        setIsLoading(false);
    }

    const onSave = async () => {
        setIsLoading(true);
        const resp = await onUpdateThemeConfig(state?.themeId, {
            configuration: updatedConfig
        })(dispatch);
        if (resp?.isUpdated) {
            loadConfig(state?.themeId);
        }
        setIsLoading(false);
    }

    const onUploadFile = async (file) => {
        const formData = new FormData();
        formData.append('images', file.originFileObj);
        formData.append('forUse', 'mediaFiles');
        const resp = await onUploadMedia(formData)(dispatch);
        if (resp) {
            return resp;
        }
        return { error: true }
    }
    const [selectedPreset, setSelectedPreset] = useState(null);
    const renderComponent = (type, pKey, fKey, value) => {
        // console.log({ type, pKey, fKey, value })
        if (type === "text") {
            return <input className={type} type="text" value={updatedConfig?.[pKey]?.[fKey] || value} onChange={(e) => setUpdatedConfig({
                ...updatedConfig,
                [pKey]: {
                    ...updatedConfig[pKey],
                    [fKey]: e.target.value,
                },
            })} />
        } else if (type === "number") {
            return <input className={type} type="number" min="0" value={updatedConfig?.[pKey]?.[fKey] || value} onChange={(e) => setUpdatedConfig({
                ...updatedConfig,
                [pKey]: {
                    ...updatedConfig[pKey],
                    [fKey]: parseInt(e.target.value),
                },
            })} />
        } else if (type === "boolean") {
            return <Switch
                className="toggle-opt"
                defaultChecked={updatedConfig?.[pKey]?.[fKey]}
                onChange={(val) => {
                    setUpdatedConfig({
                        ...updatedConfig,
                        [pKey]: {
                            ...updatedConfig[pKey],
                            [fKey]: val,
                        },
                    })
                }
                } />
        } else if (type === "color") {
            return <ColorPalette defaultColor={value} onColorChange={(val) => setUpdatedConfig({
                ...updatedConfig,
                [pKey]: {
                    ...updatedConfig[pKey],
                    [fKey]: val,
                },
            })} />
        } else if (type === "image") {
            const isMultiple = typeof value === "object";
            let images: any = null;
            if (updatedConfig[pKey] && updatedConfig[pKey][fKey] && typeof updatedConfig[pKey][fKey] === "string") {
                images = [{ url: updatedConfig[pKey][fKey] }];
            } else if (updatedConfig[pKey] && updatedConfig[pKey][fKey] && typeof updatedConfig[pKey][fKey] === "object") {
                images = updatedConfig[pKey][fKey];
            } else if (value && typeof value === "object") {
                images = value;
            } else if (value && typeof value === "string") {
                images = [{ url: value }];
            }
            return <MediaAssets key="configurator-images" isEdit={true} multiple={isMultiple} defaultValue={images} replace={true}
                onSubmit={async ({ fileList, selectedPreIcon }) => {
                    if (fileList && fileList.length > 0) {
                        const resp: any = await onUploadFile(fileList[0]);
                        if (resp && resp?.images) {
                            const url = isMultiple ? resp.images : typeof resp.images === "object" ? resp.images[0] : resp.images;
                            setUpdatedConfig({
                                ...updatedConfig,
                                [pKey]: {
                                    ...updatedConfig[pKey],
                                    [fKey]: url,
                                },
                            })
                        }
                    }
                    if (selectedPreIcon && selectedPreIcon.length > 0) {
                        setUpdatedConfig({
                            ...updatedConfig,
                            [pKey]: {
                                ...updatedConfig[pKey],
                                [fKey]: selectedPreIcon[0].url,
                            },
                        })
                    }
                }}
                onRemove={(item) => {
                    if (updatedConfig[pKey]) {
                        setUpdatedConfig({
                            ...updatedConfig,
                            [pKey]: {
                                ...updatedConfig[pKey],
                                [fKey]: "",
                            },
                        })
                    } else {
                        setUpdatedConfig({
                            ...updatedConfig,
                            [pKey]: {
                                [fKey]: "",
                            },
                        })
                    }
                }} />
        }
        return null;
    }
    const onApplyPreset = async (name) => {
        if (name) {
            let popupConfirmDetail = {
                title: "Apply Preset",
                renderContent: () => {
                    return <div>
                        The theme will be overwritten if we apply the preset. Do you confirm it?
                    </div>
                },
                yes: {
                    label: "Yes",
                    callback: async () => {
                        setIsLoading(true);
                        const resp = await onUpdateThemePreset({ preset: name }, state?.themeId)(dispatch);
                        setIsLoading(false);
                        if (resp) {
                            setPopupConfirm(null);
                            setSelectedPreset(null);
                        }
                    }
                },
                no: {
                    label: "No",
                    callback: () => { setPopupConfirm(null) }
                }
            }
            setPopupConfirm(popupConfirmDetail);
        }
    }
    return <div className="btheme" style={{ padding: 0 }}>
        {isLoading && <Loading background="#00000070"></Loading>}
        <div className="configurator">
            <div className="names">
                <div className="back-option" onClick={() => navigateTo(-1)} >
                    <ArrowLeftOutlined />
                    <label style={{ marginLeft: 10 }}>Back to themes</label>
                </div>
                <div style={{ marginTop: 46 }}>
                    {config?.configTemplate?.defaultTemplates && <div className={selectedSection === "defaultTemplates" ? "selected item" : "item"} onClick={() => setSelectedSection('defaultTemplates')}>Default Templates</div>}
                    {config?.configTemplate && config.configTemplate.order.map((key) => {
                        const value = config.configTemplate.sections[key];
                        return value && <div className={selectedSection === key ? "selected item" : "item"} onClick={() => setSelectedSection(key)} key={key}>
                            {value.name}
                        </div>
                    })}
                </div>
            </div>
            <div className="detail">
                <div className="header">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ fontSize: 20, whiteSpace: 'nowrap', fontWeight: 'bold', }} >
                            {config?.title}
                        </div>
                        <div className="divider"></div>
                        <div className="presets">
                            {config?.presets && config.presets.map((preset: any, index: number) => {
                                return <motion.button className="m-button" key={index} title={preset.displayName}
                                    onClick={() => setSelectedPreset(preset)}
                                    whileTap={{ scale: 0.9 }}
                                >
                                    <div className="preset" title={preset.displayName}
                                        onClick={() => setSelectedPreset(preset)}
                                        style={{
                                            cursor: selectedPreset?.name === preset.name ? 'default' : 'pointer',
                                            border: (!selectedPreset && index === 0) || selectedPreset?.name === preset.name ? '1px solid #949494' : '1px solid #d9d9d9',
                                        }} >
                                        <div className="p-color" style={{ background: preset.color }}></div>
                                        {selectedPreset?.name === preset.name && <Card theme={config} preset={preset} onApply={onApplyPreset} />}
                                    </div>
                                </motion.button>
                            })}
                        </div>
                    </div>
                    <div className="action">
                        <div className="search">
                            <input type="text" onChange={(e) => setSearch(e.target.value)} />
                            <img src={SearchSvg} alt="search" />
                        </div>
                        <button onClick={onSave} >Save</button>
                    </div>
                </div>
                {selectedSection && selectedSection !== "defaultTemplates" && config.configTemplate?.sections[selectedSection] && <div key={`${selectedSection}_section`} style={{ marginTop: 10 }}>
                    <div className="section" style={{ overflowY: 'auto', height: window.innerHeight - 162, paddingBottom: 16 }}>
                        {config.configTemplate?.sections[selectedSection].fields?.map((item: any) => {
                            const value = (config?.configuration[selectedSection] && config?.configuration[selectedSection][item.id]) || item.defaultValue;
                            return (search.length === 0 || item.name.toLocaleLowerCase()?.indexOf(search.toLocaleLowerCase()) > -1) && <div className="row" style={{ padding: 0 }} key={item.id}>
                                <div className="col-key">
                                    <div className="header" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                        <div className="title">{item.name}</div>
                                        <div className="desc">{item.description}</div>
                                    </div>
                                </div>
                                <div className="col-val">
                                    <div>
                                        {renderComponent(item.type, selectedSection, item.id, value)}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                }
                {
                    selectedSection && selectedSection === "defaultTemplates" && <div className="section">
                        {config?.configTemplate && config.configTemplate.defaultTemplates && Object.entries(config.configTemplate.defaultTemplates)?.map(([key, defaultValue]) => {
                            const value = (config?.configuration && config?.configuration[key]) || defaultValue;
                            return <div className="row" key={key}>
                                <div className="col-key">
                                    <div className="title">
                                        {key}
                                    </div>
                                </div>
                                <div className="col-val">
                                    {renderComponent("text", "defaultTemplates", key, config?.configuration[key] || value)}
                                </div>
                            </div>
                        })}
                    </div>
                }
            </div>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
    </div>
}

export default Configurator;