import React, { useEffect, useState, useMemo, MouseEvent, TouchEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import config from "../../../../apiConfig";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getMediaCategories } from '../../../../actions/categoryActions';
import SearchIcn from "../../../../assets/images/search.svg";
import UploadIcn from "../../../../assets/images/category_upload.svg";
// import { generateUniqueId, getBase64 } from '../../../helpers/util';
import "../../../../assets/sass/dsk/mediaAssets.sass";
import { getBase64, stopMediaPlayback } from '../../../../helpers/util';
import VideoSvg from "../../../../assets/images/media-icons/video.svg";
import AudioSvg from "../../../../assets/images/media-icons/audio.svg";
import Unknown from "../../../../assets/images/media-icons/unknown.svg";
import {
    DndContext,
    useSensor,
    useSensors,
    // DragStartEvent,
    DragEndEvent,
    closestCenter,
    MouseSensor as LibMouseSensor, TouchSensor as LibTouchSensor
} from "@dnd-kit/core"
import {
    SortableContext,
    arrayMove,
    horizontalListSortingStrategy,
} from "@dnd-kit/sortable"
import SortableItem from "./SortableItem";

interface MediaAssetsProps {
    key: any;
    className?: any;
    onSort?: any;
    uploadIcon?: any;
    accept?: any;
    uploadLimit?: any;
    isEdit: any;
    defaultValue: any;
    replace?: any;
    multiple: any;
    onRemove: any;
    onSubmit: any;
}

export const MediaAssets = (props: MediaAssetsProps) => {
    const dispatch = useDispatch();
    const {
        key,
        className,
        onSort,
        uploadIcon,
        accept,
        uploadLimit = null,
        isEdit,
        defaultValue,
        replace,
        multiple,
        onRemove,
        onSubmit
    } = props;
    const [images, setImages] = useState([]);
    // const [resetFileUploader, setResetFileUploader] = useState(generateUniqueId("media_assets_uploader"));
    const [visible, setVisible] = useState(false);
    const [selectedPreIcon, setSelectedPreIcon] = useState([]);
    const [imgSearch, setImgSearch] = useState("");
    const [preIcons, setPreIcons] = useState([]);
    const [tabSelected, setTabSelected] = useState("pre_icons");
    const [isDragStart, setDragStart] = useState(false);
    const [fileList, setFileList] = useState([]);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
    useEffect(() => {
        if (defaultValue) {
            setImages(defaultValue);
        }
        //eslint-disable-next-line
    }, [defaultValue]);
    const onSelectPreIcon = (url) => {
        if (multiple && selectedPreIcon) {
            const index = selectedPreIcon.findIndex((item) => item.url === url);
            if (index > -1) {
                setSelectedPreIcon(selectedPreIcon.filter((item) => item.url !== url));
            } else {
                setSelectedPreIcon([...selectedPreIcon, { url }]);
            }
        } else {
            setSelectedPreIcon([{ url }]);
        }
    }

    function getMediaType(input) {
        if (typeof input === 'string') {
            if (input.startsWith('data:')) {
                const mime = input.split(';')[0].split(':')[1];
                switch (mime) {
                    case 'image/jpg':
                    case 'image/jpeg':
                    case 'image/png':
                    case 'image/webp':
                    case 'image/svg':
                    case 'image/ico':
                    case 'image/gif':
                        return 'image';
                    case 'audio/mpeg':
                    case 'audio/mp3':
                    case 'audio/wav':
                    case 'audio/ogg':
                    case 'audio/midi':
                        return 'audio';
                    case 'video/mpeg':
                    case 'video/mp4':
                    case 'video/mov':
                    case 'video/webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            } else {
                const extension = input.split('.').pop().toLowerCase();
                switch (extension) {
                    case 'jpg':
                    case 'jpeg':
                    case 'png':
                    case 'webp':
                    case 'svg':
                    case 'ico':
                    case 'gif':
                        return 'image';
                    case 'mp3':
                    case 'wav':
                    case 'ogg':
                    case 'midi':
                        return 'audio';
                    case 'mp4':
                    case 'mov':
                    case 'mpeg':
                    case 'webm':
                        return 'video';
                    default:
                        return 'unknown';
                }
            }
        } else {
            return 'unknown';
        }
    }

    const iterateConditions = () => {
        return images.map((item: any, index: number) => {
            const isFinalItem = images.length - 1 === index
            return <SortableItem
                key={item.id}
                item={item}
                createDom={createDom}
                isFinalItem={isFinalItem}
            />
        });
    }

    const createDom = (item, isFinalItem = false, isDragging = false) => {
        return <div key={item.id} className="image-pre" style={{ cursor: (isEdit && multiple) ? 'grab' : 'default' }}>
            {getMediaType(item.url) === 'image' && <img src={item.url.indexOf('https://') > -1 ? item.url : `${config.baseImageUrl}${item.url}`} alt={`${item.id}_Image`} />}
            {getMediaType(item.url) === 'video' && <img src={VideoSvg} alt={`${item.id}_Video`} className="video-icon" />}
            {getMediaType(item.url) === 'audio' && <img src={AudioSvg} alt={`${item.id}_Audio`} className="audio-icon" />}
            {getMediaType(item.url) === 'unknown' && <img src={Unknown} alt={`${item.id}_Unknown`} className="unknown-icon" />}
            {isEdit && <div className="overlay" style={{ cursor: 'grab' }}>
                <div className="icons" data-no-dnd="true">
                    {replace && <EditOutlined onClick={() => setVisible(!visible)} />}
                    <DeleteOutlined onClick={(e) => { e.preventDefault(); onRemove(item); }} />
                </div>
            </div>}
        </div>
    }

    const onSelectImage = (url) => {
        const index = selectedPreIcon && selectedPreIcon.findIndex((item) => item.url === url);
        return index === null ? false : index > -1;
    }
    const getMediaTypeMemoized = useMemo(() => {
        return (url) => getMediaType(url);
    }, []);

    const tabItems: TabsProps['items'] = [{
        key: 'pre_icons',
        label: "Media Assets",
        children: <>
            <Input className="search" type="text" suffix={<img alt='Search Icon' src={SearchIcn} />} onChange={(e) => setImgSearch(e.target.value)} />
            <div className="pics">
                {preIcons.map((item) => {
                    const mediaType = getMediaTypeMemoized(item.objectUrl);
                    return ((item.tags?.find((el => el.indexOf(imgSearch.toLowerCase()) > -1)) || imgSearch.length === 0) &&
                        <div key={item.id} style={{ borderColor: onSelectImage(item.objectUrl) ? '#FF0000' : '#00000000' }}>
                            {mediaType === 'image' && <img onClick={() => onSelectPreIcon(item.objectUrl)} src={config.baseImageUrl + item.objectUrl} alt='Pre Icon' loading="lazy" />}
                            {mediaType === 'video' && <video onClick={() => onSelectPreIcon(item.objectUrl)} controls={true} src={config.baseImageUrl + item.objectUrl} />}
                            {mediaType === 'audio' && <audio onClick={() => onSelectPreIcon(item.objectUrl)} controls={true} src={config.baseImageUrl + item.objectUrl} />}
                            {mediaType === 'unknown' && <img src={Unknown} alt='Pre Icon Unknown' className="unknown-icon" />}
                        </div>
                    )
                })}
            </div>
        </>
    }, {
        key: "upload_image",
        label: "Upload Files",
        children: <>
            <Dragger
                fileList={fileList}
                showUploadList={false}
                accept={accept || "image/jpeg,image/png,image/webp,video/*,audio/*"}
                multiple={multiple}
                customRequest={() => { return false; }}
                onChange={(info) => {
                    const { status, uid } = info.file;
                    if (status === 'uploading') {
                        onHandleFileChange(info);
                    }
                    if (status === 'removed' && uid) {
                        setFileList(fileList.filter((item) => item.uid !== uid));
                    }
                }}
                onDrop={(e) => {
                    // console.log('Dropped files', e.dataTransfer.files);
                }} >
                <p className="ant-upload-drag-icon">
                    <img alt='Upload Icon' src={UploadIcn} />
                    {/* {previewImage && <img alt='Preview' className="preview-image" src={previewImage} />} */}
                </p>
                <p className="ant-upload-text">Drag a files here</p>
                <p className="ant-upload-text">---- or ----</p>
                <p className="ant-upload-hint">
                    <span className="select-icn">Browser Files</span>
                </p>
                <div style={{ display: 'flex', flexWrap: 'wrap', margin: '40px 0px', maxHeight: 'calc(58vh - 200px)', overflowY: 'auto' }}>
                    {fileList.map((file) => {
                        const mediaType = getMediaTypeMemoized(file.preview);
                        return <div key={file.uid} className="file-preview">
                            {mediaType === 'image' && <img src={file.preview} alt='Pre Icon' loading="lazy" />}
                            {mediaType === 'video' && <video controls={true} src={file.preview} />}
                            {mediaType === 'audio' && <audio controls={true} src={file.preview} />}
                            {mediaType === 'unknown' && <img src={Unknown} alt='Pre Icon Unknown' className="unknown-icon" />}
                            <div className="overlay">
                                <div className="icons">
                                    <DeleteOutlined onClick={(e) => {
                                        e.stopPropagation();
                                        setFileList(fileList.filter((item) => item.uid !== file.uid));
                                    }} />
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </Dragger>
        </>
    }]

    const loadPreIcons = async () => {
        const resp: any = await getMediaCategories()(dispatch);
        if (resp && resp.media) {
            setPreIcons(resp.media);
        }
    }

    const handleOkay = () => {
        onSubmit({ fileList, selectedPreIcon });
        stopMediaPlayback();
        setSelectedPreIcon(null);
        setFileList([]);
        setVisible(false);

    }

    const handleCancel = () => {
        stopMediaPlayback();
        setSelectedPreIcon(null);
        setFileList([]);
        setVisible(false);
    }
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }
    //     setPreviewImage(file.url || file.preview);
    // setPreviewVisible(true);
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    // };
    const onHandleFileChange = async (info) => {
        const { file } = info;
        file.preview = await getBase64(file.originFileObj);
        if (multiple) {
            info.fileList = info.fileList.map((item) => {
                if (!item.uid === item.uid) {
                    item.preview = file.preview;
                }
                return item;
            });
            setFileList(info.fileList);
        } else {
            setFileList([info.file]);
        }
        setSelectedPreIcon(null);
    }
    useEffect(() => {
        if (visible) {
            loadPreIcons();
        }
        //eslint-disable-next-line
    }, [visible]);

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        if (!over) return
        let activeIndex = active.data.current.sortable.index
        let overIndex = over.data.current.sortable.index;
        // setImages((prev) => {
        //     return arrayMove(prev, activeIndex, overIndex);
        // })
        if (isDragStart) {
            setDragStart(false);
            const reorder = arrayMove(images, activeIndex, overIndex);
            onSort && typeof onSort === 'function' && onSort(reorder);
        }
    }

    const handleDragStart = () => {
        setDragStart(true);
    }

    return <div className={`media-assets ${className}`} key={key}>
        <div className='image-container'>
            {images && <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <SortableContext disabled={!isEdit && multiple} items={images} strategy={horizontalListSortingStrategy}>
                    {iterateConditions()}
                </SortableContext>
                {/* <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
                    {activeItem ? <div>{createDom(activeItem)}</div> : null}
                </DragOverlay> */}
            </DndContext>}
            {(!uploadLimit || (images?.length <= uploadLimit)) && isEdit && ((!multiple && images?.length === 0) || (multiple)) && <div onClick={() => setVisible(!visible)} className='upload-icon'>
                <img src={uploadIcon || UploadIcn} alt='Upload Icon' />
            </div>}
        </div>
        <Modal
            width={'80%'}
            className="media-assets-upload"
            title="Upload Media Assets"
            visible={visible}
            onOk={handleOkay}
            onCancel={handleCancel}
            footer={[
                <Button key="back" className="sp_btn cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" className="sp_btn upload" onClick={handleOkay}>
                    {tabSelected === "upload_image" ? "Upload" : "Upload Files"}
                </Button>
            ]}
        >
            <Tabs defaultActiveKey="1" items={tabItems} onChange={(key) => setTabSelected(key)} />
        </Modal>
    </div>
};

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
    let cur = event.target as HTMLElement;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement as HTMLElement;
    }

    return true;
};

export class MouseSensor extends LibMouseSensor {
    static activators = [{ eventName: 'onMouseDown', handler }] as typeof LibMouseSensor['activators'];
}

export class TouchSensor extends LibTouchSensor {
    static activators = [{ eventName: 'onTouchStart', handler }] as typeof LibTouchSensor['activators'];
}