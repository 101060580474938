import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import WhatsAppIcon from '../../../../../../assets/images/whatsApp.png';
import Loading from '../../../../common/Loading';
import UploadIcon from "../../../../../../assets/images/gallery-add.svg";
import { getStoreDetails, onUpdateSiteSettings } from '../../../../../../actions/brandAction';
import { useDispatch } from 'react-redux';
import { uuidv4 } from '../../../../../../helpers/util';
import { onUploadMedia } from '../../../../../../actions/commonAction';
import { MediaAssets } from '../../../../common/mediaAssets';

const Brand = (props: any) => {
    const dispatch = useDispatch();
    const [brandName, setBrandName] = useState(null);
    const [brandLogo, setBrandLogo] = useState([]);
    const [favIcon, setFavIcon] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [whatsAppNo, setWhatsAppNo] = useState(null);
    useEffect(() => {
        getBrand();
        //eslint-disable-next-line
    }, [])

    const getBrand = async () => {
        setIsLoading(true);
        const resp: any = await getStoreDetails()(dispatch);
        setIsLoading(false);
        const { settings } = resp;
        if (resp?.brandName) {
            setBrandName(resp.brandName);
        }
        if (settings?.favicon) {
            setFavIcon([settings.favicon]);
        }
        if (settings?.logo) {
            setBrandLogo([settings.logo]);
        }
        if (settings?.whatsAppNo) {
            settings.whatsAppNo = settings.whatsAppNo.toString();
        }
    }
    const onSave = async () => {
        let isError = false;
        const payload = {}
        if (payload) {
            setIsLoading(true);
            payload['favicon'] = favIcon && favIcon.length > 0 ? favIcon[0] : '';
            payload['logo'] = brandLogo && brandLogo.length > 0 ? brandLogo[0] : '';
            payload['brandName'] = brandName;
            if (isError) {
                setIsLoading(false);
                return;
            }
            await onUpdateSiteSettings(payload)(dispatch);
            setIsLoading(false);
        }
    }
    const loadFavIcon = () => {
        let imageList = [];
        if (favIcon && favIcon.length > 0) {
            imageList.push({
                url: favIcon[0],
                id: uuidv4()
            });
        }
        return imageList;
    }
    const loadBrandLogo = () => {
        let imageList = [];
        if (brandLogo && brandLogo.length > 0) {
            imageList.push({
                url: brandLogo[0],
                id: uuidv4()
            });
        }
        return imageList;
    }
    const onUploadFiles = (fileList, type) => {
        const uploadPromises = fileList.map(async (item) => {
            const formData = new FormData();
            formData.append('files', item.originFileObj);
            formData.append('forUse', 'mediaFiles');
            const resp = await onUploadMedia(formData)(dispatch);
            return resp;
        });
        Promise.all(uploadPromises)
            .then((resp: any) => {
                let urls = [];
                if (resp && resp.length > 0) {
                    urls = resp.map((r) => {
                        return r.files && r.files.length > 0 && r.files[0]
                    });
                    if (type === 'brand-logo') {
                        setBrandLogo(urls);
                    } else if (type === 'fav-icon') {
                        setFavIcon(urls);
                    }
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error uploading files:", error);
                return { error: true }
            });
    }
    return <div>
        {isLoading && <Loading background="#00000070"></Loading>}
        <div className="container">
            <div style={{ marginBottom: 14 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-end" }}>
                    <div className="header">
                        <div>Set your store brand</div>
                        <div style={{ fontSize: 14, color: "#4e4e4e", fontWeight: "normal" }}>Configure the store brand.</div>
                    </div>
                    <button style={{ width: 160, fontSize: 14 }} onClick={onSave} className="sp_btn" >Save Changes</button>
                </div>
            </div>
            <div className="division">
                <div className="section" style={{ display: 'flex' }}>
                    <div style={{ width: '40%' }}>
                        <div className="title" style={{ marginBottom: 10 }}> Brand Name</div>
                        <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Set your brand name here</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative'
                    }}>
                        <div style={{ flexGrow: 1 }}>
                            <Input style={{ width: 400 }} value={brandName} defaultValue={brandName} onChange={(e) => setBrandName(e.target.value)} className="input" placeholder="Enter brand name" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="division">
                <div className="section" style={{ display: 'flex' }}>
                    <div style={{ width: '40%' }}>
                        <div className="title" style={{ marginBottom: 10 }}> Brand Logo</div>
                        <div style={{ flexGrow: 1, fontSize: 13 }} className="key" > Set your brand logo here SVG, PNG or JPG  (max. 500 x 500)</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <MediaAssets isEdit={true} multiple={false} defaultValue={loadBrandLogo()}
                                key="brand-logo"
                                uploadIcon={UploadIcon}
                                onSubmit={async ({ fileList, selectedPreIcon }) => {
                                    let urls = [];
                                    if (selectedPreIcon && selectedPreIcon.length > 0) {
                                        urls = selectedPreIcon.map((item) => {
                                            return item.url
                                        });
                                    }
                                    if (fileList && fileList.length > 0) {
                                        onUploadFiles(fileList, "brand-logo")
                                    } else {
                                        setBrandLogo(urls);
                                    }
                                }}
                                onRemove={() => {
                                    setBrandLogo([]);
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="division">
                <div className="section" style={{ display: 'flex' }}>
                    <div style={{ width: '40%' }}>
                        <div className="title" style={{ marginBottom: 10 }}> Fav Icon</div>
                        <div style={{ flexGrow: 1, fontSize: 13 }} className="key" > Set your fav icon here SVG, PNG or JPG  (max. 500 x 500)</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative'
                    }}>
                        <MediaAssets isEdit={true} multiple={false} defaultValue={loadFavIcon()}
                            key="fav-icon"
                            uploadIcon={UploadIcon}
                            onSubmit={async ({ fileList, selectedPreIcon }) => {
                                let urls = [];
                                if (selectedPreIcon && selectedPreIcon.length > 0) {
                                    urls = selectedPreIcon.map((item) => {
                                        return item.url
                                    });
                                }
                                if (fileList && fileList.length > 0) {
                                    onUploadFiles(fileList, "fav-icon")
                                } else {
                                    setFavIcon(urls);
                                }
                            }}
                            onRemove={() => {
                                setFavIcon([]);
                            }} />
                    </div>
                </div>
            </div>
            <div className="division">
                <div className="section" style={{ display: 'flex' }}>
                    <div style={{ width: '40%' }}>
                        <div className="title" style={{ marginBottom: 10 }}>WhatsApp Number</div>
                        <div style={{ flexGrow: 1, fontSize: 13 }} className="key" >Set your whatsapp number here</div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        position: 'relative'
                    }}>
                        <div style={{ flexGrow: 1 }}>
                            <Input prefix={<img alt='WhatsApp Icon' src={WhatsAppIcon} style={{ width: 30 }} />} style={{ width: 400 }} value={whatsAppNo} type={"number"} defaultValue={whatsAppNo} onChange={(e) => setWhatsAppNo(e.target.value)} className="input" placeholder="Enter whatsApp number" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Brand;