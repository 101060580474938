import { ArrowLeftOutlined } from "@ant-design/icons";
import { Drawer, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import PopupConfirm from "../common/PopupConfirm";
import Loading from "../common/Loading";
import { RuleBuilder } from "../common/ruleBuilder";
import SearchSvg from "../../../assets/images/search.svg"
import EmptySvg from "../../../assets/images/empty.svg"
import NoImageAvailableIcon from "../../../assets/images/No-image-available.png";
import TickSvg from "../../../assets/images/tick.svg"
import DeleteSvg from "../../../assets/images/delete.svg"
import { getL10N } from "../../../helpers/middleware";
import UploadIcon from "../../../assets/images/gallery-add.svg";
import { getProducts } from "../../../actions/productActions";
import { useDispatch } from "react-redux";
import apisUrl from "../../../apiConfig";
import { MediaAssets } from "../common/mediaAssets";
import InputValidation from "../common/InputValidation";
import { addCollection, updateCollection } from "../../../actions/collectionActions";
import { uuidv4 } from "../../../helpers/util";
import { onUploadMedia } from "../../../actions/commonAction";

const { Option } = Select;
const Save = (props: any) => {
    const { collection, isVisible } = props;
    let errorInfo = {
        title: {
            type: null,
            message: null
        },
        slug: {
            type: null,
            message: null
        },
        type: {
            type: null,
            message: null
        },
        manualType: {
            type: null,
            message: null
        },
    };
    const dispatch = useDispatch();
    const WindowHeight = window.innerHeight;
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(errorInfo);
    const [productSearch, setProductSearch] = useState("");
    const [selectedProductSearch, setSelectedProductSearch] = useState("");
    const [isProductBrowser, setIsProductBrowser] = useState(false);
    const [filterProducts, setFilterProducts] = useState([]);
    const [popupConfirm, setPopupConfirm] = useState(null);
    const [ruleBuilderValidate, setRuleBuilderValidate] = useState(null);
    const [images, setImages] = useState([]);
    const [icons, setIcons] = useState([]);
    const [selectProducts, setSelectProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isNewCondition, setNewCondition] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [slug, setSlug] = useState("");
    const [seoTitle, setSEOTitle] = useState("");
    const [seoDescription, setSEODescription] = useState("");
    const [ruleBuilderError, setRuleBuilderError] = useState(null);
    const [rule, setRule] = useState(null);
    const [type, setType] = useState("automatic");
    const [products, setProducts] = useState([]);
    const onClose = () => {
        setIsEdit(false);
        props.onClose();
    }
    useEffect(() => {
        if (collection) {
            setTitle(collection && collection.title ? collection.title : "");
            setDescription(collection && collection.description ? collection.description : "");
            setSlug(collection && collection.slug ? collection.slug : "");
            setSEOTitle(collection && collection.seoTitle ? collection.seoTitle : "");
            setSEODescription(collection && collection.seoDescription ? collection.seoDescription : "");
            setType(collection && collection.type ? collection.type : "automatic");
            setImages(collection && collection.image ? [collection.image] : []);
            setIcons(collection && collection.icon ? [collection.icon] : []);
            setSelectedProducts(collection && collection.products ? collection.products : []);
            setRule({ conditions: collection && collection.conditions ? collection.conditions : null });
            setIsEdit(collection && collection.id ? false : true);
        }
    }, [collection]);
    const onValidate = (e) => {
        e.preventDefault();
        let isError = false;
        if (!title || title.length === 0) {
            errorInfo.title.type = "error";
            errorInfo.title.message = "Title is required";
            isError = true;
        } else {
            errorInfo.title.type = null;
            errorInfo.title.message = null;
        }
        if (!slug || slug.length === 0) {
            errorInfo.slug.type = "error";
            errorInfo.slug.message = "Slug is required";
            isError = true;
        } else {
            errorInfo.slug.type = null;
            errorInfo.slug.message = null;
        }
        if (type === "automatic" && !isError) {
            setRuleBuilderValidate("initiated");
        } else if (type === "manual") {
            if (selectedProducts.length === 0) {
                errorInfo.manualType.type = "error";
                errorInfo.manualType.message = "Please select at least one product to save collection.";
            } else {
                onSubmit();
            }
        }
        setError(errorInfo);
    }
    const onSubmit = async () => {
        const payload = {
            title,
            description,
            slug,
            seoTitle,
            seoDescription,
            type,
            image: images.length > 0 ? images[0] : '',
            icon: icons.length > 0 ? icons[0] : '',
        };
        if (type === "automatic") {
            const conditions = JSON.parse(JSON.stringify(rule.conditions));
            conditions && conditions.forEach((condition, i) => {
                delete condition.id;
            });
            payload['conditions'] = conditions;
        } else if (type === "manual") {
            payload['products'] = selectedProducts;
        }
        setPopupConfirm({
            title: collection?.id ? 'Update Collection' : 'Create Collection',
            renderContent: () => { return <div><span style={{ color: "#000" }}>Are you sure you want to {collection?.id ? 'update' : 'create'} <span style={{ fontWeight: "bold" }}>{title}</span> collection?</span></div > },
            yes: {
                label: collection?.id ? "Update" : "Create",
                callback: async () => {
                    let resp = null;
                    setIsLoading(true);
                    if (collection?.id) {
                        resp = await updateCollection(payload, collection.id)(dispatch);
                    } else {
                        resp = await addCollection(payload)(dispatch);
                    }
                    setPopupConfirm(null);
                    setIsLoading(false);
                    if (!resp.error) {
                        onClose();
                    }
                }
            },
            no: {
                label: "Cancel",
                callback: () => { setPopupConfirm(null); }
            }
        });
    }
    useEffect(() => {
        if (ruleBuilderValidate === "completed" && !ruleBuilderError && rule?.conditions && rule?.conditions.length > 0) {
            setRuleBuilderValidate(null);
            onSubmit();
        }
        // eslint-disable-next-line
    }, [ruleBuilderValidate, ruleBuilderError, rule]);
    const loadProducts = async () => {
        setIsLoading(true);
        let respProducts = await getProducts()(dispatch);
        setIsLoading(false);
        if (respProducts) {
            setProducts(respProducts);
        }
    }
    const onUploadFiles = (fileList, type) => {
        const uploadPromises = fileList.map(async (item) => {
            const formData = new FormData();
            formData.append('files', item.originFileObj);
            formData.append('forUse', 'mediaFiles');
            const resp = await onUploadMedia(formData)(dispatch);
            return resp;
        });
        Promise.all(uploadPromises)
            .then((resp: any) => {
                let urls = [];
                if (resp && resp.length > 0) {
                    urls = resp.map((r) => {
                        return r.files && r.files.length > 0 && r.files[0]
                    });
                    if (type === 'images') {
                        setImages([...images, ...urls]);
                    } else if (type === 'icons') {
                        setIcons([...icons, ...urls]);
                    }
                }
            })
            .catch(error => {
                // Handle errors if any
                console.error("Error uploading files:", error);
                return { error: true }
            });
    }
    const loadImages = () => {
        let imageList = [];
        for (let url of images) {
            imageList.push({
                url,
                id: uuidv4()
            })
        }
        return imageList;
    }
    const loadIcons = () => {
        let imageList = [];
        for (let url of icons) {
            imageList.push({
                url,
                id: uuidv4()
            })
        }
        return imageList;
    }
    const onFilterProducts = () => {
        let filterResults = [];
        for (let p of products) {
            if (productSearch) {
                let title = getL10N(p, "title", null).i10n;
                if ((title && title.toLowerCase().indexOf(productSearch.toLowerCase()) > -1) || p.sku?.includes(productSearch)) {
                    filterResults.push(p);
                }
            } else {
                filterResults.push(p);
            }
        }
        setFilterProducts(filterResults)
    }

    const onFilterSelectedProducts = (p) => {
        if (selectedProductSearch && selectedProductSearch.length > 0) {
            let title = getL10N(p, "title", null).i10n;
            if ((title && title.toLowerCase().indexOf(selectedProductSearch.toLowerCase()) > -1)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (isProductBrowser) {
            setSelectProducts(selectedProducts);
            onFilterProducts();
        }
        // eslint-disable-next-line
    }, [isProductBrowser && productSearch, products]);

    const onCloseBrowserProducts = () => {
        setIsProductBrowser(false);
    }

    const onRemoveSelectedProducts = (pId: any) => {
        let selected = selectedProducts.filter((p) => p !== pId);
        setSelectedProducts(selected);
    }

    useEffect(() => {
        if (isProductBrowser || type === "automatic") {
            loadProducts();
        }
        // eslint-disable-next-line
    }, [isProductBrowser, type]);

    const loadSelectedProduct = (pId: any) => {
        const product = products.find((p: any) => p.id === pId);
        const isVisible = onFilterSelectedProducts(product);
        if (isVisible) {
            if (product) {
                return <div className="row product" style={{ marginTop: 15 }} key={pId}>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <img src={`${apisUrl.baseImageUrl}${product.images[0]}`} alt={product.title} />
                        <div>{product.title}</div>
                    </div>
                    <div>
                        <img src={DeleteSvg} style={{ cursor: "pointer", height: 18 }} alt={"Delete"} onClick={() => { onRemoveSelectedProducts(product.id) }} />
                    </div>
                </div>
            }
        }
    }
    return <Drawer
        closable={false}
        onClose={onClose}
        width={"100%"}
        className="collection-save"
        visible={isVisible}
        footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="sp_btn" onClick={onClose} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
            {!isEdit && <div className="sp_btn" onClick={() => { setIsEdit(true) }} style={{ width: 120, fontSize: 14 }}>Edit</div>}
            {isEdit && <div className="sp_btn" onClick={onValidate} style={{ width: 120, fontSize: 14 }}>{collection && collection.id ? "Update" : "Add"}</div>}

        </div>}
    >
        <div className="header">
            <ArrowLeftOutlined className="arrow" onClick={onClose}></ArrowLeftOutlined>
            <div className="title">{collection && collection.id ? "Update Collection" : "Add Collection"}</div>
        </div>
        <div className="content" style={{ height: WindowHeight - 80 + "px", overflow: 'auto' }}>
            <div className="detail" >
                <div className="section">
                    <div className="group">
                        <div className="label">Title *</div>
                        <InputValidation type={error.title.type} message={error.title.message}>
                            <input disabled={!isEdit} type="text" placeholder="Enter collection title" className="value" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                        </InputValidation>
                    </div>
                    <div className="group">
                        <div className="label">Description</div>
                        {/* <InputValidation type={error.desc.type} message={error.desc.message}> */}
                        <textarea disabled={!isEdit} placeholder="Enter collection description" rows={6} className="value" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                        {/* </InputValidation> */}
                    </div>
                    <div className="group" style={{ marginBottom: 0 }}>
                        <div className="label">Slug *</div>
                        <InputValidation type={error.slug.type} message={error.slug.message}>
                            <input disabled={!isEdit} type="text" placeholder="Enter collection slug" className="value" value={slug} onChange={(e) => { setSlug(e.target.value) }} />
                        </InputValidation>
                    </div>
                </div>
                <div className="section">
                    <div className="title" style={{ marginBottom: 10 }}>SEO</div>
                    <div className="group">
                        <div className="label">Title</div>
                        {/* <InputValidation type={error.seoTitle.type} message={error.seoTitle.message}> */}
                        <input disabled={!isEdit} type="text" placeholder="Enter collection title" className="value" value={seoTitle} onChange={(e) => { setSEOTitle(e.target.value) }} />
                        {/* </InputValidation> */}
                    </div>
                    <div className="group" style={{ marginBottom: 0 }}>
                        <div className="label">Description</div>
                        {/* <InputValidation type={error.seoDesc.type} message={error.seoDesc.message}> */}
                        <textarea disabled={!isEdit} placeholder="Enter collection description" rows={6} className="value" value={seoDescription} onChange={(e) => { setSEODescription(e.target.value) }} />
                        {/* </InputValidation> */}
                    </div>
                </div>
                <div className="section">
                    <div className="title" style={{ marginBottom: 15 }}>Collection Type</div>
                    <div className="group">
                        <div className="action">
                            <Select disabled={!isEdit} className="type" value={type} onChange={(val) => { setType(val); setRuleBuilderError(null); setError(errorInfo); }} placeholder="Type" >
                                <Option value="automatic">Automatic</Option>
                                <Option value="manual">Manual</Option>
                            </Select>
                            {error.manualType.message && <div className="rulebuilder-error">{error.manualType.message}</div>}
                            {ruleBuilderError && <div className="rulebuilder-error">{ruleBuilderError}</div>}
                            {isEdit && type === "automatic" && <button className="sp_btn" onClick={() => { setNewCondition(true); }}>Add Condition</button>}
                        </div>
                        <div className="type-desc">
                            {type === "manual" && "Introduce products into this collection individually, one after the other."}
                            {type === "automatic" && "Any products that meet the criteria you've specified will be automatically included in this collection, both now and in the future."}
                        </div>
                        <div className="divider"></div>
                        {type === "automatic" && <div className="automatic">
                            <RuleBuilder
                                readonly={!isEdit}
                                hideHeader={true}
                                isNewCondition={isNewCondition}
                                onResetNewCondition={() => { setNewCondition(false); }}
                                isValidate={ruleBuilderValidate}
                                onResetValidate={(status) => { setRuleBuilderValidate(status); }}
                                hideAction={true}
                                hideGroup={true}
                                onSave={(rule, status) => { setRule(rule); setRuleBuilderValidate(status); }}
                                key={'rule-builder'}
                                errorMessage={(err) => { setRuleBuilderError(err); }}
                                products={products}
                                rule={rule}
                                customConditions={[{
                                    id: "price",
                                    label: "Price"
                                }, {
                                    id: "salePrice",
                                    label: "Sale Price"
                                }, {
                                    id: "title",
                                    label: "Title"
                                }, {
                                    id: "subtitle",
                                    label: "Subtitle"
                                }, {
                                    id: "stock",
                                    label: "Stock"
                                }, {
                                    id: "productSku",
                                    label: "Product SKU"
                                }]}
                                onClose={() => { setRule(null); }} />
                        </div>}
                        {type === "manual" && <div className="manual">
                            <div className="title" style={{ marginBottom: 15 }}>Products</div>
                            <div className="row">
                                <Input disabled={!isEdit} onChange={(event) => setSelectedProductSearch(event.target.value)} style={{ flexGrow: 1 }} placeholder="Search Products" type="text" suffix={<img src={SearchSvg} alt={"Search"} />}></Input>
                                <button className="sp_btn" onClick={() => setIsProductBrowser(true)} style={{ height: 40, marginLeft: 12, padding: "0px 34px", width: "auto" }}>Browser</button>
                            </div>
                            {selectedProducts.length > 0 && <div className="type-desc">Total Products: <span style={{ marginLeft: 4, color: "#121212" }}>{selectedProducts.length}</span></div>}
                            <div>
                                {selectedProducts.length > 0 ? selectedProducts.map((p) =>
                                    loadSelectedProduct(p)
                                ) : <div className="empty" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 54
                                }}>
                                    <img src={EmptySvg} alt={"Empty"} />
                                    <div className="type-desc" style={{ marginBottom: 0 }}>There are currently no items available in this collection.</div>
                                    <div className="type-desc" style={{ marginTop: 0 }}>Feel free to browse to find and add products.</div>
                                </div>}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div>
                <div className="icon">
                    <div className="title">Collection Image</div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {!isEdit && images.length === 0 && <div>
                            <img alt='Not Available' src={NoImageAvailableIcon} style={{ height: 74 }} />
                        </div>}
                        <MediaAssets key="collection-images" isEdit={isEdit} multiple={false} defaultValue={loadImages()}
                            uploadIcon={UploadIcon}
                            onSubmit={async ({ fileList, selectedPreIcon }) => {
                                let urls = [];
                                if (selectedPreIcon && selectedPreIcon.length > 0) {
                                    urls = selectedPreIcon.map((item) => {
                                        return item.url
                                    });
                                }
                                if (fileList && fileList.length > 0) {
                                    onUploadFiles(fileList, "images")
                                } else {
                                    setImages([...images, ...urls]);
                                }
                            }}
                            onRemove={(item) => {
                                let newImages = images.filter((img) => img !== item.url);
                                setImages(newImages);
                            }} />
                    </div>
                </div>
                <div className="icon" style={{ marginTop: 8 }}>
                    <div className="title">Collection Icon</div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {!isEdit && icons.length === 0 && <div>
                            <img alt='Not Available' src={NoImageAvailableIcon} style={{ height: 74 }} />
                        </div>}
                        <MediaAssets isEdit={isEdit} multiple={false} defaultValue={loadIcons()}
                            key="collection-icons"
                            uploadIcon={UploadIcon}
                            onSubmit={async ({ fileList, selectedPreIcon }) => {
                                let urls = [];
                                if (selectedPreIcon && selectedPreIcon.length > 0) {
                                    urls = selectedPreIcon.map((item) => {
                                        return item.url
                                    });
                                }
                                if (fileList && fileList.length > 0) {
                                    onUploadFiles(fileList, "icons")
                                } else {
                                    setIcons([...icons, ...urls]);
                                }
                            }}
                            onRemove={(item) => {
                                let newIcons = icons.filter((icon) => icon !== item.url);
                                setIcons(newIcons);
                            }} />
                    </div>
                </div>
            </div>
        </div>
        {popupConfirm && <PopupConfirm config={popupConfirm}></PopupConfirm>}
        {isLoading && <Loading background="#00000070"></Loading>}
        <Modal
            closable={false}
            width={"30%"}
            className="add-products"
            open={isProductBrowser}
            footer={null}
        >
            <div className="content">
                <div className="header">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowLeftOutlined className="arrow" onClick={onCloseBrowserProducts}></ArrowLeftOutlined>
                        <div className="title">Add Products</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="sp_btn" onClick={onCloseBrowserProducts} style={{ boxShadow: 'none', width: 120, fontSize: 14, marginRight: 10, border: '1px solid #121212', color: "#000", background: "#fff" }}>Cancel</div>
                        <div className="sp_btn" onClick={() => { setSelectedProducts(selectProducts); setSelectProducts([]); setIsProductBrowser(false); }} style={{ width: 134, fontSize: 14 }}>Submit</div>
                    </div>
                </div>
                <div className="body">
                    <div className="row" style={{ width: "100%" }}>
                        <Input onChange={(event) => setProductSearch(event.target.value)} style={{ flexGrow: 1 }} placeholder="Search Products" type="text" suffix={<img src={SearchSvg} alt={"Search"} />}></Input>
                    </div>
                    <div style={{ maxHeight: window.innerHeight - 160, width: '100%' }}>
                        <div style={{ width: 'inherit' }}>
                            {filterProducts && filterProducts.length > 0 && filterProducts.map((product: any) => {
                                return <div className="row product" style={{ marginTop: 15 }}>
                                    <div className={selectProducts.indexOf(product.id) > -1 ? "select" : "unselect"} onClick={() => {
                                        if (selectProducts.indexOf(product.id) > -1) {
                                            setSelectProducts(selectProducts.filter((p) => p !== product.id))
                                        } else {
                                            setSelectProducts([...selectProducts, product.id]);
                                        }
                                    }}>
                                        {selectProducts.indexOf(product.id) > -1 && <img src={TickSvg} alt={"Tick"} />}
                                    </div>
                                    <img src={`${apisUrl.baseImageUrl}${product.images[0]}`} alt={product.title} />
                                    <div>{product.title}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </Drawer>
}

export default Save;